import type { ComponentStyleConfig } from "@chakra-ui/react"
import alertConfig from "./components/alertConfig"
import BadgeConfig from "./components/badgeConfig"
import { BoxConfig } from "./components/boxConfig"
import ButtonConfig from "./components/buttonConfig"
import DrawerConfig from "./components/drawerConfig"
import HeadingConfig from "./components/headingConfig"
import InputConfig from "./components/inputConfig"
import ModalConfig from "./components/modalConfig"
import popoverConfig from "./components/popoverConfig"
import skeletonConfig from "./components/skeletonConfig"
import SwitchConfig from "./components/switchConfig"
import TabsConfig from "./components/tabsConfig"
export enum ComponentConfigurations {
    Button = "ButtonConfig"
}

const componentConfig: { [key: string]: ComponentStyleConfig } = {
    Badge: BadgeConfig,
    Box: BoxConfig,
    Button: ButtonConfig,
    Modal: ModalConfig,
    Heading: HeadingConfig,
    Input: InputConfig,
    Drawer: DrawerConfig,
    Tabs: TabsConfig,
    Switch: SwitchConfig,
    Skeleton: skeletonConfig,
    Popover: popoverConfig,
    Alert: alertConfig
}

export default componentConfig
