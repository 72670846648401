import { tabsAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
    tab: {
        fontWeight: "semibold"
    }
})

const primaryVariant = definePartsStyle({
    tab: {
        color: "gray.300",
        fontWeight: "semibold",
        fontsize: "md",
        mb: "-1px",
        pb: "4",
        borderBottom: "2px",
        borderColor: "transparent",
        _hover: {
            color: "gray.400",
            borderColor: "inherit",
            borderBottom: "2px"
        },
        _selected: {
            color: "green.500",
            borderColor: "inherit",
            borderBottom: "2px"
        }
    },
    tablist: {
        borderBottom: "1px",
        // borderColor: mode('gray.100', 'gray.100'),
        mb: "8"
    },
    tabpanel: {
        p: 0
    }
})

const blxSoftRounded = definePartsStyle((props) => {
    return {
        tab: {
            borderRadius: "full",
            fontWeight: "semibold",
            color: "gray.300",
            _selected: {
                color: "white",
                bg: "green.500"
            },
            _disabled: {
                bg: null,
                color: "gray.200"
            },
            _hover: {
                bg: "gray.50",
                color: "gray.400"
            }
        }
    }
})

const TabsConfig = defineMultiStyleConfig({
    baseStyle,
    variants: {
        primary: primaryVariant,
        blxSoftRounded
    },
    defaultProps: {
        variant: "primary"
    }
})

export default TabsConfig
