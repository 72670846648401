import type { ComponentStyleConfig } from "@chakra-ui/react"
import { tailwindConstants } from "tailwind-config"

const themeColor = tailwindConstants.theme.extend.colors
const fontSizes = tailwindConstants.theme.fontSize

const headingConfig: ComponentStyleConfig = {
    // Variants  map to themes
    baseStyle: {
        color: themeColor.dark,
        fontWeight: "bold"
    },
    sizes: {
        h1: {
            fontSize: fontSizes["6xl"][0],
            mb: "3",
            lineHeight: fontSizes["6xl"][1]
        },
        h2: {
            fontSize: fontSizes["5xl"][0],
            mb: "2",
            lineHeight: fontSizes["5xl"][1]
        },
        h3: {
            fontSize: fontSizes["4xl"][0],
            mb: "2",
            lineHeight: fontSizes["4xl"][1]
        },
        h4: {
            fontSize: fontSizes["3xl"][0],
            mb: "2",
            lineHeight: fontSizes["3xl"][1]
        },
        h5: {
            fontSize: fontSizes["2xl"][0],
            mb: "1",
            lineHeight: fontSizes["2xl"][1]
        },
        h6: {
            fontSize: fontSizes.lg[0],
            mb: "4px",
            lineHeight: fontSizes.lg[1]
        }
    },

    variants: {
        dark: {
            color: themeColor.dark
        },
        medium: {
            color: themeColor.medium
        },

        primary: {
            color: themeColor.primary
        },
        secondary: {
            color: themeColor.secondary
        },
        info: {
            color: themeColor.info
        },
        success: {
            color: themeColor.success
        },
        warning: {
            color: themeColor.warning
        },
        error: {
            color: themeColor.error
        },
        light: {
            color: themeColor.light
        }
    },

    defaultProps: {
        size: "h2",
        variant: "dark"
    }
}

export default headingConfig
