import type React from "react"
import create from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

import type { AddDrawerParams, DrawerIds, DrawerOpenStatus } from "../types"

interface NestedDrawers {
    drawerContent: {
        [k in keyof any]: {
            title: React.ReactNode
            content: React.ReactNode
            ignoreOverlay?: boolean
        }
    }
    drawerDrawerOpenStatus: DrawerOpenStatus
    toggleDrawer: (id: DrawerIds) => void
    addDrawer: (data: AddDrawerParams) => void
    setDrawerTitleFromDetailConfig: (id: DrawerIds, title: React.ReactNode) => void
    setDrawerContents: (id: DrawerIds, contents: React.ReactNode) => void
    setNextDrawer: () => void
    setDrawerIsMaximized: (position: number, isMaximized: boolean) => void
    nextDrawer: DrawerIds | null
    drawerMaximizedStatus: {
        [0]: boolean
        [1]: boolean
        [2]: boolean
    }
}

const defaultDrawerDrawerOpenStatus: DrawerOpenStatus = {
    0: false,
    1: false,
    2: false
}

const defaultDrawerContent = { title: null, content: null }

const defaultData = {
    drawerDrawerOpenStatus: defaultDrawerDrawerOpenStatus,
    drawerContent: {
        0: defaultDrawerContent,
        1: defaultDrawerContent,
        2: defaultDrawerContent
    },
    drawerMaximizedStatus: {
        0: false,
        1: false,
        2: false
    },
    nextDrawer: null
}

const useNestedDrawers = create<NestedDrawers>()(
    devtools(
        immer((set, get) => ({
            ...defaultData,
            setNextDrawer: () => {
                const openDrawers = get().drawerDrawerOpenStatus
                const emptyDrawers = Object.keys(openDrawers).filter(
                    (item) => openDrawers[item] === false
                )
                let nextOpenDrawer: DrawerIds = null
                if (Number(emptyDrawers.at(0)) === 0) nextOpenDrawer = 0
                if (Number(emptyDrawers.at(0)) === 1) nextOpenDrawer = 1
                if (Number(emptyDrawers.at(0)) === 2) nextOpenDrawer = 2
                set((state) => {
                    state.nextDrawer = nextOpenDrawer
                })
            },
            addDrawer: ({ drawerId, content, title, ignoreOverlay }) => {
                set((state) => {
                    state.drawerContent[drawerId] = { content, title, ignoreOverlay }
                    state.drawerDrawerOpenStatus[drawerId] = true
                })
                get().setNextDrawer()
            },
            toggleDrawer: (drawerId: DrawerIds) => {
                if (get().drawerDrawerOpenStatus[drawerId]) {
                    set({
                        drawerDrawerOpenStatus: {
                            ...get().drawerDrawerOpenStatus,
                            [drawerId]: false
                        },
                        drawerContent: {
                            ...get().drawerContent,
                            [drawerId]: defaultDrawerContent
                        }
                    })
                } else {
                    set({
                        drawerDrawerOpenStatus: {
                            ...get().drawerDrawerOpenStatus,
                            [drawerId]: true
                        }
                    })
                }
                get().setNextDrawer()
            },
            setDrawerTitleFromDetailConfig: (id, title) => {
                set((state) => {
                    state.drawerContent[id].title = title
                })
            },
            setDrawerContents: (id, contents) => {
                set((state) => {
                    state.drawerContent[id].content = contents
                })
            },
            setDrawerIsMaximized: (position, isMaximized) => {
                set((state) => {
                    state.drawerMaximizedStatus[position] = isMaximized
                })
            }
        })),
        {
            name: "useNestedDrawers"
        }
    )
)

export default useNestedDrawers
