import type { ComponentStyleConfig } from "@chakra-ui/react"
import { tailwindConstants } from "tailwind-config"

const themeColor = tailwindConstants.theme.extend.colors
const color = tailwindConstants.theme.colors

const ButtonConfig: ComponentStyleConfig = {
    baseStyle: {
        borderColor: themeColor.dark,
        borderRadius: "0.25rem",
        borderWidth: "2px",
        color: color.black,
        m: null,
        _hover: {
            bg: themeColor.dark,
            color: color.white,
            borderColor: themeColor.dark
        },
        __active: {
            shadow: tailwindConstants?.theme?.extend?.boxShadow?.default
        },
        __focus: {
            shadow: tailwindConstants?.theme?.extend?.boxShadow?.default
        },
        text: themeColor.dark,
        fontWeight: "semibold",
        transition: "all 200ms ease-in",
        _loading: {
            pointerEvents: "none",
            opacity: 0.6
        }
    },
    sizes: {
        xs: {
            fontSize: "xs",
            py: "3",
            px: "3",
            h: null,
            m: null
        },
        sm: {
            fontSize: "sm",
            py: "2.5",
            px: "4",
            h: null,
            m: null
        },
        md: {
            fontSize: "md",
            py: "3",
            px: "6",
            h: null
        },
        lg: {
            fontSize: "lg",
            py: "6",
            px: "2rem",
            h: null
        }
    },
    variants: {
        menu: {
            rounded: 4,
            w: { base: "full", md: "240px", lg: "300" },
            py: 2,
            textAlign: "start",
            borderWidth: 2,
            borderColor: color.gray[100],
            fontWeight: "semi-bold",
            bg: "transparent",
            color: color.gray[500],
            fontSize: "14px",
            px: 4,

            _hover: {
                borderColor: color.gray[500],
                bg: "none",
                color: color.black
            },
            _disabled: {
                bg: color.gray[50],
                color: color.gray[300],
                _hover: {
                    borderColor: color.gray[100]
                }
            }
        },
        default: {
            bg: color.white,
            color: color.black,
            borderColor: themeColor.dark,
            borderWidth: "2px",
            _hover: {
                bg: themeColor.dark,
                color: themeColor.light
            }
        },
        primary: {
            bg: themeColor.primary,
            color: color.white,
            borderColor: themeColor.primary,
            borderWidth: "2px",
            _hover: {
                bg: color.green[600],
                borderColor: color.green[600]
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.primary
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.primary
            }
        },
        secondary: {
            bg: themeColor.secondary,
            color: color.white,
            borderColor: `${themeColor.secondary}`,
            borderWidth: "2px",
            _hover: {
                bg: color.blue[600],
                borderColor: color.blue[600]
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.secondary
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.secondary
            }
        },
        info: {
            bg: themeColor.info,
            color: color.white,
            borderColor: `${themeColor.info}`,
            borderWidth: "2px",
            _hover: {
                bg: color.blue[400],
                borderColor: color.blue[400]
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.info
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.info
            }
        },
        success: {
            bg: themeColor.success,
            color: color.white,
            borderColor: themeColor.success,
            borderWidth: "2px",
            _hover: {
                bg: color.green[700],
                borderColor: color.green[700]
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.success
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.success
            }
        },
        warning: {
            bg: themeColor.warning,
            color: color.black,
            borderColor: themeColor.warning,
            borderWidth: "2px",
            _hover: {
                bg: color.yellow[600],
                borderColor: color.yellow[600]
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.warning
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.warning
            }
        },
        error: {
            bg: themeColor.error,
            color: color.white,
            borderColor: themeColor.error,
            borderWidth: "2px",
            _hover: {
                bg: color.red[600],
                borderColor: color.red[600]
            },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.error
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.error
            }
        },
        light: {
            bg: themeColor.light,
            color: color.black,
            borderColor: themeColor.light,
            borderWidth: "2px",
            _hover: { bg: color.gray[100] },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.default
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.default
            }
        },
        dark: {
            bg: themeColor.dark,
            color: color.white,
            borderColor: themeColor.dark,
            borderWidth: "2px",
            _hover: { bg: color.gray[400], borderColor: color.gray["400"] },
            _active: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.dark
            },
            _focus: {
                shadow: tailwindConstants?.theme?.extend?.boxShadow?.dark
            }
        },
        link: {
            color: themeColor.info,
            bg: "transparent",
            borderColor: "transparent",
            borderWidth: "2px",
            _hover: {
                color: color.blue[500],
                bg: "transparent",
                shadow: "none",
                borderColor: "transparent",
                textDecor: "none"
            }
        }
    },

    defaultProps: {
        size: "md",
        variant: "default"
    }
}

export default ButtonConfig
