import { popoverAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    popoverAnatomy.keys
)

/** https://chakra-ui.com/docs/components/popover/theming */
const baseStyle = definePartsStyle({
    header: {
        fontWeight: "bold"
    }
})
const sizes = {}
const variants = {
    dark: definePartsStyle({
        content: {
            bg: "gray.800",
            color: "light",
            border: "none"
        },
        arrow: {
            backgroundColor: "gray.800 !important"
        }
    })
}

const multiStyleConfig = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        variant: "dark"
    }
})
const popoverConfig = multiStyleConfig

export default popoverConfig
