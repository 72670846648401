"use client"
import { Alert, AlertDescription, AlertIcon, AlertTitle, ScaleFade } from "@chakra-ui/react"
import classNames from "classnames"
import type React from "react"
import { useAlerts } from "../state/useAlerts"

//TODO: Use the icons in the alert object to display the correct icon, instead of the default AlertIcon
export const GlobalAlerts: React.FC = () => {
    const { alerts } = useAlerts()

    return (
        <div className='global-alerts pointer-events-none fixed bottom-4 left-0 right-0 z-[99999] flex h-auto w-full flex-col items-center justify-center space-y-4'>
            {alerts.map((alert) => (
                <ScaleFade initialScale={0.9} key={alert.id} in>
                    <Alert
                        gap={3}
                        variant='subtle'
                        key={alert.id}
                        status={alert.theme}
                        className={classNames(
                            "w-auto max-w-xl",
                            `alert--${alert.status} rounded-md`
                        )}
                    >
                        <AlertIcon />
                        <AlertTitle>{alert.title}</AlertTitle>
                        <AlertDescription>{alert.description}</AlertDescription>
                    </Alert>
                </ScaleFade>
            ))}
        </div>
    )
}
