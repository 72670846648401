"use client"

import { Provider } from "react-redux"
import { store } from "services"

import { CacheProvider } from "@chakra-ui/next-js"
import { ChakraProvider, cookieStorageManager, extendTheme } from "@chakra-ui/react"

import theme from "../config/chakra/theme"

import type { ThemeConfig } from "@chakra-ui/react"

export const chakraThemeConfig: ThemeConfig = extendTheme({
    ...theme,
    initialColorMode: "system",
    useSystemColorMode: true
})

export const ContextProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <CacheProvider>
        <div className='min-h-screen w-full'>
            <ChakraProvider
                portalZIndex={999}
                theme={chakraThemeConfig}
                colorModeManager={cookieStorageManager}
            >
                <Provider store={store}>{children}</Provider>
            </ChakraProvider>
        </div>
    </CacheProvider>
)
