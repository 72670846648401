import type React from "react"
import create from "zustand"
import { devtools } from "zustand/middleware"

export interface IAlert {
    id: string
    title: string
    description?: React.ReactNode
    icon: string
    theme: "info" | "warning" | "success" | "error" | "loading"
    status: "active" | "dismissed"
    duration?: number
}

interface IuseAlerts {
    alerts: IAlert[]
    addAlert: (alert: IAlert) => void
    dismissAlert: (id: string) => void
    resetAlerts: () => void
}

export const useAlerts = create<IuseAlerts>()(
    devtools(
        (set, get) => ({
            alerts: [],
            addAlert: async (alert) => {
                const allAlerts = get().alerts
                set({ alerts: [...allAlerts, alert] })

                if (alert?.duration) {
                    setTimeout(() => {
                        get().dismissAlert(alert?.id)
                    }, alert.duration)
                }
            },
            dismissAlert: async (id) => {
                const alerts = get().alerts
                const alertIndex = alerts.findIndex((alert) => alert.id === id)
                if (alerts[alertIndex]) {
                    alerts[alertIndex].status = "dismissed"
                    set({ alerts: alerts })
                    const alertsFiltered = alerts.filter((alert) => alert.id !== id)

                    setTimeout(() => {
                        set({ alerts: alertsFiltered })
                    }, 600)
                }
            },
            resetAlerts: async () => {
                const allAlerts = get().alerts
                allAlerts.forEach((item) => get().dismissAlert(item.id))
            }
        }),
        { name: "alerts-store" }
    )
)
